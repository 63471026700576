import { Box, Typography } from "@material-ui/core";
import { graphql } from 'gatsby';
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useContext, useEffect, useState } from 'react';
import FormTable from "../../components/formTable";
import Layout from "../../components/layout";
import QuestionCard from "../../components/questionCard";
import { QuestionnaireContext } from "../../context/questionnaireContext";
import theme from '../../gatsby-theme-material-ui-top-layout/theme';

export default function Part3() {
  const {t} = useTranslation();
  const [answer, setAnswer] = useState(null);
  const [nextDisabled, setNextDisable] = useState(true);
  let previousEntry = "";
  const [questionnaireData, setQuestionnaireData] = useContext(QuestionnaireContext);

  const columns = [t("Participate in the Québec Prenatal Screening Program."), t("Equal importance"), t("Proceed outside of the Québec Prenatal Screening Program.")]
  const rows = [
    [
      t("I would prefer to choose from the tests offered by the Québec Prenatal Screening Program at each stage, according to the program requirements."),
      t("Equal importance"),
      t("I would prefer to proceed with the test(s) I want to undergo without having to meet the program requirements.")
    ],
    [
      t("I should follow the recommendations of the Québec Prenatal Screening Program, based on my risk profile."),
      t("Equal importance"),
      t("I could proceed with the screening test(s) as soon as they are feasible.")
    ],
    [
      t("I could have a free screening that is covered by the Régie de l'assurance maladie du Québec (RAMQ)"),
      t("Equal importance"),
      t("I would be comfortable with the idea of paying a fee that may vary depending on the test(s) chosen and where the screening is performed.")
    ],
  ]

  const choices = [
    {value:"In PDPQ", label:t("Participate in the Québec Prenatal Screening Program.")},
    {value:"Out PDPQ", label:t("Proceed outside of the Québec Prenatal Screening Program.")}
  ]

  const handleChange = (event) => {
    setAnswer(event.target.value);
  }

  if (typeof window !== 'undefined') {
    previousEntry = localStorage.getItem("screening3") || "";
  }

  useEffect(() => {
    if(answer !== null)
      setNextDisable(false);
      localStorage.setItem("screening3", answer);
  }, [answer]);

  useEffect(() => {
    if(previousEntry !== '') {
      setAnswer(previousEntry)
    }
  }, []);

  return (
    <Layout pageTitle={t("Where to undergo my prenatal screening test?")} maxWidth={false} previous="/screening/part2" next="/screening/part4" nextDisabled={nextDisabled}>
      <Box marginTop={theme.spacing(0.5)}>
        <Typography component="span" gutterBottom={true}>
            <strong>
              {t("Here are some factors to consider that may influence your decision concerning whether or not to participate in the Québec Prenatal Screening Program.")}
            </strong> 
            <br/> 
            <br/>
            {t("Think about what is most important to you.")}
            <br/>
            <br/>
            {t("Blank")}
            <br/>
            <br/>
          </Typography>
      </Box>
      <FormTable rows={rows} columns={columns} part="screening3"></FormTable>
      <QuestionCard question={t("What is your decision?")} choices={choices} answer={answer} handleChange={handleChange}/>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
